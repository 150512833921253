import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ConnectionChecker from "./ConnectionChecker";
import Homepage from "./Home";
import Contact from "./Contact";
import About from "./About";
import CoursesPage from "./CoursesPage";
import GalleryPage from "./GalleryPage";
import Login from "./Login";
import Dashboard from "./Dashboard";
import ProfilePage from "./ProfilePage";
import CoursePage from "./user/CoursePage";
import FeesPage from "./user/FeePage";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import StudentCertificate from "./StudentCertificate";
import Career from "./Career";
import SocialMediaPage from "./SocialMedia";
import TestimonialsPage from "./Testimonials";
import CertificatesPage from "./Certificates";
import ProfileData from "./Profile";
import NotFound from "./NotFound"; // Import NotFound component
import LeadersPage from "./Leader";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/gallery/*" element={<GalleryPage />} />
          <Route path="/certificate" element={<CertificatesPage />} />
          <Route path="/social" element={<SocialMediaPage />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/leader" element={<LeadersPage />} />

          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />

          <Route
            path="*"
            element={
              isAuthenticated ? <StudentRoutes /> : <Navigate to="/login" />
            }
          />

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
      <ConnectionChecker />
    </Router>
  );
};

const StudentRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="studentprofile" element={<ProfilePage />} />
      <Route path="studentprofile2" element={<ProfileData />} />
      <Route path="studentcourse" element={<CoursePage />} />
      <Route path="studentcertificates" element={<StudentCertificate />} />
      <Route path="studentfees" element={<FeesPage />} />
    </Routes>
  );
};

export default App;
