import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 700px;
  width: 90%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure position relative for absolute positioning of close button */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
`;

const LoadingText = styled.p`
  text-align: center;
`;

const OfferImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const PopupContent = styled.div`
  margin-top: 10px;
`;

const CheckboxContainer = styled.div`
  text-align: center;
  margin: 10px 0;
`;

const StyledCheckbox = styled.input`
  margin-right: 5px;
`;

const KeepSameButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: #007bff;
  margin-top: 10px;
`;

const OfferPopup = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [offerImage, setOfferImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOfferImage = async () => {
      const storage = getStorage(); // Initialize Firebase storage
      const storageRef = ref(storage, "offers"); // Reference to 'offers' directory in Firebase storage

      try {
        const imagesList = await listAll(storageRef); // List all images in 'offers' directory
        // Assuming you want to fetch the first image in the list
        if (imagesList.items.length > 0) {
          const imageUrl = await getDownloadURL(imagesList.items[0]); // Get download URL for the first image
          setOfferImage(imageUrl); // Set state with the URL of the offer image
          setLoading(false); // Set loading state to false
        } else {
          console.error("No images found in 'offers' folder");
          setLoading(false); // Set loading state to false
        }
      } catch (error) {
        console.error("Error fetching offer image:", error);
        setLoading(false); // Set loading state to false
      }
    };

    fetchOfferImage();
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    localStorage.setItem("hideOfferPopup", "false"); // Reset the localStorage value to false on close
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      const hideUntil = new Date();
      hideUntil.setDate(hideUntil.getDate() + 1); // Set expiration to 24 hours from now
      localStorage.setItem("hideOfferPopup", hideUntil.toISOString());
    } else {
      localStorage.removeItem("hideOfferPopup");
    }
  };

  useEffect(() => {
    // Check if there's a saved item in localStorage
    const hideOfferPopupUntil = localStorage.getItem("hideOfferPopup");
    if (hideOfferPopupUntil === "false") {
      setShowPopup(true);
    } else if (hideOfferPopupUntil) {
      const hideUntilTime = new Date(hideOfferPopupUntil).getTime();
      const currentTime = new Date().getTime();
      if (currentTime < hideUntilTime) {
        // If current time is less than hide until time, do not show the popup
        setShowPopup(false);
      } else {
        setShowPopup(true);
      }
    }
  }, []);

  const handleKeepSame = () => {
    // Handle the action when "Keep the same" button is clicked
    // Here, you can implement specific behavior such as closing the popup or any other action
    alert("Well We do not show this untill 24 Hours");
    setShowPopup(false); // For example, close the popup
    localStorage.setItem("hideOfferPopup", "false"); // Reset the localStorage value to false on keep same
  };

  return (
    showPopup && (
      <ModalBackground>
        <ModalContainer>
          <CloseButton onClick={handleClose}>&times;</CloseButton>
          {loading ? (
            <LoadingText>Loading...</LoadingText>
          ) : (
            <>
              {offerImage && <OfferImage src={offerImage} alt="Offer" />}
              <PopupContent>
                <p>Discover our latest offers!</p>
                <CheckboxContainer>
                  <label>
                    <StyledCheckbox
                      type="checkbox"
                      onChange={handleCheckboxChange}
                    />
                    Do not show for 24 hours
                  </label>
                </CheckboxContainer>
                <KeepSameButton onClick={handleKeepSame}>
                  Keep the same
                </KeepSameButton>
              </PopupContent>
            </>
          )}
        </ModalContainer>
      </ModalBackground>
    )
  );
};

export default OfferPopup;
