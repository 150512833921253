import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useNavigate, useLocation } from "react-router-dom";
import Preloader from "./Preloader"; // Import the preloader component

const GalleryPage = () => {
  const [folders, setFolders] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [loading, setLoading] = useState(true); // State for controlling preloader visibility
  const navigate = useNavigate();
  const location = useLocation();
  const storage = getStorage(); // Initialize Firebase Storage

  useEffect(() => {
    const fetchFolders = async () => {
      setLoading(true); // Start loading
      try {
        const storageRef = ref(storage, "images");
        const folderList = await listAll(storageRef);
        setFolders(folderList.prefixes); // prefixes are subfolders
      } catch (error) {
        console.error("Error fetching folders:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchFolders();
  }, [storage]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const folderName = query.get("folder");
    if (folderName) {
      fetchImages(folderName);
    } else {
      setSelectedFolder(null);
      setImages([]);
    }
  }, [location.search]);

  const fetchImages = async (folderName) => {
    setLoading(true); // Start loading
    try {
      const folderRef = ref(storage, `images/${folderName}`);
      const imageList = await listAll(folderRef);
      const imageUrls = await Promise.all(
        imageList.items.map((itemRef) => getDownloadURL(itemRef))
      );
      setImages(imageUrls);
      setSelectedFolder(folderName);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleFolderClick = (folderName) => {
    navigate(`?folder=${encodeURIComponent(folderName)}`);
  };

  return (
    <Container>
      {loading ? (
        <PreloaderContainer>
          <Preloader />
        </PreloaderContainer>
      ) : selectedFolder ? (
        <DetailContainer>
          <DetailTitle>{selectedFolder}</DetailTitle>
          <DetailImages>
            {images.map((image, index) => (
              <DetailImageItem key={index} src={image} alt={`Image ${index}`} />
            ))}
          </DetailImages>
        </DetailContainer>
      ) : (
        <>
          <Title>Gallery</Title>
          <FoldersGrid>
            {folders.map((folder) => (
              <FolderCard key={folder.name} onClick={() => handleFolderClick(folder.name)}>
                <FolderTitle>{folder.name}</FolderTitle>
              </FolderCard>
            ))}
          </FoldersGrid>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const FoldersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const FolderCard = styled.div`
  border: 2px solid teal;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  padding: 20px;
  transition: transform 0.2s;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const FolderTitle = styled.h2`
  margin: 0;
  color: #333;
  font-size: 1.5rem;
`;

const DetailContainer = styled.div`
  padding: 10px;
  text-align: center;
`;

const DetailTitle = styled.h2`
  font-size: 2rem;
  margin: 20px 0;
`;

const DetailImages = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default to 1 column */
  gap: 20px;
  justify-items: center;

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for small screens */
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for large screens */
  }
`;

const DetailImageItem = styled.img`
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  max-height: 40vh; /* Max height of 40% of the viewport height */
  object-fit: cover; /* Ensure image covers the container */
  border: 2px solid teal;
  border-radius: 8px;
  cursor: pointer;

  @media (min-width: 576px) {
    max-height: 30vh; /* Smaller max height for small screens */
  }

  @media (min-width: 768px) {
    max-height: 35vh; /* Medium max height for medium screens */
  }

  @media (min-width: 1200px) {
    max-height: 40vh; /* Larger max height for large screens */
  }
`;

const PreloaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
`;

export default GalleryPage;
