// Initialize Firebase services


// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCigz4gTvMGbvu5bNXOCj-IYcWT6ohdycY",
  authDomain: "shreefoundationon.firebaseapp.com",
  databaseURL: "https://shreefoundationon-default-rtdb.firebaseio.com",
  projectId: "shreefoundationon",
  storageBucket: "shreefoundationon.appspot.com",
  messagingSenderId: "225271099727",
  appId: "1:225271099727:web:ac75d39bbc750775b3153a",
  measurementId: "G-E83Q1F3G75"
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

