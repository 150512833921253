// About.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Preloader from './Preloader'; // Adjust the path according to your project structure

const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate a 2-second loading time
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <AboutContainer>
      <Title>About</Title>
      <Paragraph>
        <b>SITA RAM ELEGANT EDUCATION FOUNDATION ORGANIZATION,</b>
      </Paragraph>
      <Paragraph>
        Welcome to the About page of Sita Ram Elegant Education Foundation! We are a non-profit organization that is committed to providing quality education and opportunities for the underprivileged children in India.
      </Paragraph>
      <Paragraph>
        Our mission is to create a world where every child has access to quality education regardless of their background. We believe that education is the key to unlocking a better future for our children and our society as a whole. We strive to create a world where every child has the opportunity to reach their full potential and achieve their dreams.
      </Paragraph>
      <Paragraph>
        Sita Ram Elegant Education Foundation was founded by a group of passionate individuals who wanted to make a positive impact on the lives of children in India. We started with a small group of students, but over the years, we have grown to become a leading provider of education services in India.
      </Paragraph>
      <Paragraph>
        Our programs and initiatives are designed to address the various challenges that underprivileged children face when it comes to education. We offer a wide range of educational programs, including after-school programs, tutoring, mentorship, and scholarships. We also provide access to educational resources such as books, computers, and other learning materials.
      </Paragraph>
      <Paragraph>
        At Sita Ram Elegant Education Foundation, we believe in the power of collaboration. We work closely with our partner organizations, government agencies, and other stakeholders to ensure that we are providing the best possible services to our students. We also believe in the importance of innovation and constantly strive to find new and better ways to serve our students.
      </Paragraph>
      <Paragraph>
        Our team is made up of passionate and dedicated individuals who share our vision of a world where every child has access to quality education. We are committed to creating a supportive and inclusive work environment where everyone can thrive.
      </Paragraph>
      <Paragraph>
        Thank you for taking the time to learn more about Sita Ram Elegant Education Foundation. If you are interested in supporting our mission or learning more about our programs, please don't hesitate to contact us.
      </Paragraph>
      <ContactInfo>Reach us at <Email>admin@sreef.in</Email></ContactInfo>
    </AboutContainer>
  );
};

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left; /* Align text to the left */
`;

const Title = styled.h2`
  text-align: center; /* Center the title */
  font-size: 2rem;
  color: teal;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 15px;
`;

const ContactInfo = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin-top: 30px;
  text-align: center; /* Center align the contact info */
`;

const Email = styled.a`
  color: teal;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default About;
