import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { auth, db } from './firebase'; // Adjust the path as per your Firebase setup
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Preloader from './Preloader'; // Import the Preloader component

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  color: #333;
  padding: 20px;
`;

const ProfileCard = styled.div`
  padding: 40px;
  border-radius: 12px;
  max-width: 900px;
  width: 100%;
  margin: 20px 0;
  animation: fadeIn 0.6s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #4a90e2;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  font-size: 1.1rem;
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  font-weight: 500;
  width: 30%;
  text-align: left;
`;

const InfoSeparator = styled.div`
  width: 10%;
  text-align: center;
  color: #888;
`;

const InfoData = styled.div`
  color: #333;
  font-size: 0.9rem;
  font-weight: 600;
  width: 60%;
  text-align: left;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
`;

const CertificateImage = styled.img`
  width: 100px;
  height: auto;
  margin-top: 10px;
  cursor: pointer;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
`;

const FullImage = styled.img`
  max-width: 90vw;
  max-height: 90vh;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 30px;
  text-align: center;
`;

const StudentCertificate = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setUserUID] = useState(null); // State to store the user's UID
  const [selectedImage, setSelectedImage] = useState(null); // State to handle the selected image for the modal

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const docRef = doc(db, 'students', uid); // Correct the collection name to 'students'
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.log('No matching user found!');
          setError('No user found');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUID(user.uid); // Set the user's UID
        fetchUserData(user.uid);
      } else {
        setLoading(false);
        setError('No user is logged in');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageClick = (imageURL) => {
    setSelectedImage(imageURL);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (loading) {
    return <Preloader />; // Display preloader while loading
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <PageContainer>
      <ProfileCard>
        <Title>Certificates</Title>
        <Subtitle>For: {userData.fullName}</Subtitle>

        {userData?.certificates?.length > 0 ? (
          userData.certificates.map((certificate, index) => (
            <div key={index}>
              <InfoBlock>
                <InfoLabel>Certificate Name</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{certificate.certificateName}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Issued On</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{certificate.date}</InfoData>
              </InfoBlock>
              {certificate.fileURL && (
                <InfoBlock>
                  <InfoLabel>Image</InfoLabel>
                  <InfoSeparator>-</InfoSeparator>
                  <CertificateImage
                    src={certificate.fileURL}
                    alt={certificate.certificateName}
                    onClick={() => handleImageClick(certificate.fileURL)}
                  />
                </InfoBlock>
              )}
              {index < userData.certificates.length - 1 && <Divider />} {/* Add divider except for the last certificate */}
            </div>
          ))
        ) : (
          <InfoData>No certificates available</InfoData>
        )}
      </ProfileCard>

      {/* Modal for full-size image */}
      {selectedImage && (
        <Modal onClick={handleCloseModal}>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
            <FullImage src={selectedImage} alt="Full-size certificate" />
          </ModalContent>
        </Modal>
      )}
    </PageContainer>
  );
};

export default StudentCertificate;
