// CoursesPage.js
import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "./firebase"; // Adjust the import path if necessary
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Preloader from "./Preloader"; // Adjust the import path if necessary

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesSnapshot = await getDocs(collection(db, "courses"));
        const coursesList = coursesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCourses(coursesList);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const courseId = query.get("course");
    if (courseId) {
      fetchCourseDetails(courseId);
    } else {
      setSelectedCourse(null);
    }
  }, [location.search]);

  const fetchCourseDetails = async (courseId) => {
    try {
      const courseDoc = doc(db, "courses", courseId);
      const courseSnapshot = await getDoc(courseDoc);
      if (courseSnapshot.exists()) {
        setSelectedCourse(courseSnapshot.data());
      } else {
        console.error("No such course!");
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  const handleCourseClick = (courseId, courseTitle) => {
    navigate(`?course=${courseId}&title=${encodeURIComponent(courseTitle)}`);
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <Container>
      {selectedCourse ? (
        <DetailContainer>
          <DetailImage
            src={selectedCourse.imageUrl}
            alt={selectedCourse.title}
          />
          <DetailTitle>{selectedCourse.title}</DetailTitle>
          <DetailDescription>{selectedCourse.description}</DetailDescription>
          <DetailInfo>Duration: {selectedCourse.duration}</DetailInfo>
          <DetailInfo>Fee: {selectedCourse.fee}</DetailInfo>
        </DetailContainer>
      ) : (
        <>
          <Title>Courses</Title>
          <CardsContainer>
            {courses.map((course) => (
              <CourseCard
                key={course.id}
                onClick={() => handleCourseClick(course.id, course.title)}
              >
                <CourseImage src={course.imageUrl} alt={course.title} />
                <CourseTitle>{course.title}</CourseTitle>
              </CourseCard>
            ))}
          </CardsContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  padding: 10px;
`;

const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const CourseCard = styled.div`
  flex: 1 1 100%; /* Fills the width on small screens */
  max-width: 300px; /* Limits width on larger screens */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;

  @media (min-width: 768px) {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
  }

  @media (min-width: 1200px) {
    flex: 1 1 calc(25% - 20px); /* Four cards per row on large screens */
  }
`;

const CourseImage = styled.img`
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: fill; /* Ensures the image covers the entire box */
  border-bottom: 1px solid #ccc;
`;

const CourseTitle = styled.h2`
  font-size: 1.5rem;
  margin: 10px 0;
`;

const DetailContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const DetailImage = styled.img`
  width: 95%;
  max-height: 400px;
  object-fit: fill;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const DetailTitle = styled.h2`
  font-size: 2rem;
  margin: 20px 0;
`;

const DetailDescription = styled.p`
  font-size: 1.2rem;
  margin: 10px 0;
  text-align: left;
`;

const DetailInfo = styled.p`
  font-size: 1.2rem;
  margin: 10px 0;
`;

export default CoursesPage;
