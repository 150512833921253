import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Preloader from './Preloader'; // Adjust the path as per your project structure

const SocialMediaDashboard = () => {
  const [socialMediaItems, setSocialMediaItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const db = getFirestore();

  useEffect(() => {
    const fetchSocialMediaItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'socialMediaItems'));
        const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSocialMediaItems(itemsList);
      } catch (error) {
        console.error('Error fetching social media items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSocialMediaItems();
  }, [db]);

  return (
    <DashboardContainer>
      {loading && <Preloader />} {/* Show Preloader if loading state is true */}
      <ItemsList>
        {socialMediaItems.length > 0 ? (
          socialMediaItems.map((item) => (
            <ItemLink key={item.id} href={item.link} target="_blank">
              <ItemImage src={item.imageUrl} alt={item.title} />
            </ItemLink>
          ))
        ) : (
          <p>No social media items available.</p>
        )}
      </ItemsList>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const ItemsList = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const ItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

export default SocialMediaDashboard;
