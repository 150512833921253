import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f2f5;
  padding: 20px;
  min-height: 100vh;
`;

const ProfileCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  width: 100%;
  margin: 20px;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  font-weight: 500;
  width: 45%;
  text-align: left;
`;

const InfoSeparator = styled.div`
  width: 20%;
  text-align: center;
  color: #888;
`;

const InfoData = styled.div`
  color: #333;
  font-size: 0.9rem;
  font-weight: 600;
  width: 45%;
  text-align: left;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid black;
`;

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setUserUID] = useState(null);

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const docRef = doc(db, "students", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.log("No matching user found!");
          setError("No user found");
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUID(user.uid);
        fetchUserData(user.uid);
      } else {
        setLoading(false);
        setError("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <PageContainer>
      <ProfileCard>
        {userData ? (
          <>
            <ProfileImage
              src={userData.imageUrl || "path-to-default-user-image.jpg"}
              alt="User Profile"
            />
            <Title>Profile Details</Title>
            <InfoContainer>
              <InfoBlock>
                <InfoLabel>Username</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.username}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Full Name</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.fullName}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Email</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.email}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Mobile Number</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.mobileNumber}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Address</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.address}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Father's Name</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.fatherName}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Mother's Name</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.motherName}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Date of Birth</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.dob}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Gender</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.gender}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Category</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.category}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Area Pincode</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.pincode}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Aadhar Number</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{userData.aadharNumber}</InfoData>
              </InfoBlock>
            </InfoContainer>
          </>
        ) : (
          <Error>No user data to display.</Error>
        )}
      </ProfileCard>
    </PageContainer>
  );
};

export default ProfilePage;
