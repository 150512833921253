import React from "react";
import styled from "styled-components";
import SocialMediaDashboard from "./SocialMedia";

const FooterContainer = styled.footer`
  background-color: teal;
  padding: 40px 20px;
  border-top: 1px solid #e5e5e5;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 20px 0;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const FooterHeading = styled.h4`
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const FooterLink = styled.a`
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterSocial = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  a {
    color: #333;
    margin: 0 10px;
    font-size: 20px;
    text-decoration: none;

    &:hover {
      color: #007bff;
    }
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #666;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialMediaDashboard/>
      <FooterSocial>
        <a href="https://example.com"><i className="fab fa-facebook-f"></i></a>
        <a href="https://example.com"><i className="fab fa-twitter"></i></a>
        <a href="https://example.com"><i className="fab fa-youtube"></i></a>
        <a href="https://example.com"><i className="fab fa-linkedin-in"></i></a>
      </FooterSocial>
      <FooterWrapper>
        <FooterColumn>
          <FooterHeading>About Sreef</FooterHeading>
          <FooterLink href="#">Overview</FooterLink>
          <FooterLink href="#">Goals</FooterLink>
          <FooterLink href="#">Expert support</FooterLink>
          <FooterLink href="#">Mobile app for Android</FooterLink>
          <FooterLink href="#">Mobile app for iOS</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>Learning & support</FooterHeading>
          <FooterLink href="#">Your guide to Sreef</FooterLink>
          <FooterLink href="#">Sreef Help Center</FooterLink>
          <FooterLink href="#">Sreef Announcements</FooterLink>
          <FooterLink href="#">Sreef Community</FooterLink>
          <FooterLink href="#">Primer</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>Developers & partners</FooterHeading>
          <FooterLink href="#">Developer Site</FooterLink>
          <FooterLink href="#">API (AdWords API)</FooterLink>
          <FooterLink href="#">Scripts</FooterLink>
          <FooterLink href="#">API</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>Related products</FooterHeading>
          <FooterLink href="#">Analytics</FooterLink>
          <FooterLink href="#">Business Profile</FooterLink>
          <FooterLink href="#">Chrome</FooterLink>
          <FooterLink href="#">Insights finder</FooterLink>
          <FooterLink href="#">Performance planner</FooterLink>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>More solutions</FooterHeading>
          <FooterLink href="#">Business Solutions</FooterLink>
          <FooterLink href="#">Workspace</FooterLink>
          <FooterLink href="#">Think with Sreef</FooterLink>
          <FooterLink href="#">AdSense</FooterLink>
          <FooterLink href="#">AdMob</FooterLink>
        </FooterColumn>
      </FooterWrapper>
      <FooterBottom>
        <p>
          &copy; {new Date().getFullYear()} Sreef. All rights reserved. | Privacy | Terms | About Sreef | Help
        </p>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
