import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Adjust the import path if necessary
import styled, { keyframes } from "styled-components";

const CertificatesPage = () => {
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  useEffect(() => {
    const fetchCertificates = async () => {
      const certificatesCollection = collection(db, "certificates"); // Adjust collection name as necessary
      const certificatesSnapshot = await getDocs(certificatesCollection);
      const certificatesList = certificatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCertificates(certificatesList);
    };

    fetchCertificates();
  }, []);

  const handleCardClick = (certificate) => {
    setSelectedCertificate(selectedCertificate?.id === certificate.id ? null : certificate);
  };

  useEffect(() => {
    if (selectedCertificate) {
      const timer = setTimeout(() => {
        setSelectedCertificate(null);
      }, 5000); // Hide information after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [selectedCertificate]);

  return (
    <Container>
      <Header>
        <Title>Company Certifications</Title>
        <Subtitle>
          Discover the certifications offered by our esteemed foundation. Each
          certificate represents a milestone in educational achievement and
          professional development, contributing to the betterment of society.
        </Subtitle>
      </Header>
      <CardsContainer>
        {certificates.map((certificate) => (
          <CertificateCard
            key={certificate.id}
            onClick={() => handleCardClick(certificate)}
            isSelected={selectedCertificate?.id === certificate.id}
          >
            <CertificateImage src={certificate.imageUrl || "https://via.placeholder.com/400x400"} alt={certificate.name} />
            <CertificateContent isVisible={selectedCertificate?.id === certificate.id}>
              <CertificateName>{certificate.name}</CertificateName>
              <CertificateDescription>{certificate.description}</CertificateDescription>
            </CertificateContent>
            <CertificateOverlay isVisible={selectedCertificate?.id === certificate.id} />
            {selectedCertificate?.id === certificate.id && <ProgressBar />}
          </CertificateCard>
        ))}
      </CardsContainer>
    </Container>
  );
};

const progressBarAnimation = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: black;
  background-color: #f4f4f4;
  padding: 10px;
  position: relative;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  overflow-x: auto;
  max-height: 70vh;
  padding: 5px;
  position: relative;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const CertificateCard = styled.div`
  flex: 0 0 auto;
  width: 185px;
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  position: relative;
  border: 2px solid transparent;
  transition: transform 0.3s ease-in-out;
`;

const CertificateImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: fill;
  border: 1px solid teal;
`;

const CertificateContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const CertificateOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background: teal;
  animation: ${progressBarAnimation} 5s linear forwards;
`;

const CertificateName = styled.h2`
  font-size: 1.5rem;
  margin: 10px 0;
`;

const CertificateDescription = styled.p`
  font-size: 1rem;
  margin: 5px 0;
`;

export default CertificatesPage;
