import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { auth, db } from "../firebase"; // Adjust the path as per your Firebase setup
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  color: #333;
  padding: 10px;
`;

const ProfileCard = styled.div`
  border-radius: 12px;
  max-width: 900px;
  width: 100%;
  margin: 20px 0;
  animation: fadeIn 0.6s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  color: #4a90e2;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  font-size: 1.1rem;
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const InfoLabel = styled.div`
  color: #888;
  font-size: 0.9rem;
  font-weight: 500;
  width: 30%;
  text-align: left;
`;

const InfoSeparator = styled.div`
  width: 10%;
  text-align: center;
  color: #888;
`;

const InfoData = styled.div`
  color: #333;
  font-size: 0.9rem;
  font-weight: 600;
  width: 60%;
  text-align: left;
`;

const CourseContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const CourseTitle = styled.h3`
  color: #4a90e2;
  margin-bottom: 15px;
  font-size: 1.5rem;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
`;

const FeesPage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setUserUID] = useState(null); // State to store the user's UID
  const [feesSummary, setFeesSummary] = useState({}); // State to store the fees summary

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const docRef = doc(db, "students", uid); // Correct the collection name to 'students'
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);

          // Calculate total fees received
          const totalFeesReceived =
            data.fees?.reduce((total, fee) => total + fee.amount, 0) || 0;

          // Calculate total course fees
          const totalCourseFees =
            data.courses?.reduce(
              (total, course) => total + course.courseFees,
              0
            ) || 0;

          // Calculate remaining fees
          const remainingFees = totalCourseFees - totalFeesReceived;

          setFeesSummary({
            totalFeesReceived,
            totalCourseFees,
            remainingFees,
          });
        } else {
          console.log("No matching user found!");
          setError("No user found");
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUID(user.uid); // Set the user's UID
        fetchUserData(user.uid);
      } else {
        setLoading(false);
        setError("No user is logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <PageContainer>
      <ProfileCard>
        <Title>Course and Fee Details</Title>
        <Subtitle>For: {userData.fullName}</Subtitle>

        {/* Fees Summary */}
        <CourseContainer>
          <CourseTitle>Fees Summary</CourseTitle>
          <InfoBlock>
            <InfoLabel>Total Course Fees</InfoLabel>
            <InfoSeparator>-</InfoSeparator>
            <InfoData>{feesSummary.totalCourseFees}</InfoData>
          </InfoBlock>
          <InfoBlock>
            <InfoLabel>Total Fees Received</InfoLabel>
            <InfoSeparator>-</InfoSeparator>
            <InfoData>{feesSummary.totalFeesReceived}</InfoData>
          </InfoBlock>
          <InfoBlock>
            <InfoLabel>Remaining Fees</InfoLabel>
            <InfoSeparator>-</InfoSeparator>
            <InfoData>{feesSummary.remainingFees} (You Have To Pay More)</InfoData>
          </InfoBlock>
        </CourseContainer>

        {/* Courses */}
        <CourseContainer>
          <CourseTitle>Courses</CourseTitle>
          {userData?.courses?.map((course, index) => (
            <courseDetails key={index}>
              <InfoBlock>
                <InfoLabel>Center Name</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.centerName}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Course Name</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.courseName}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Course Fees</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.courseFees}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>University</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.university}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Enrollment Session</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.enrollmentSession}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Center Head</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.centerHead}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Start Date</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.startDate}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>End Date</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{course.endDate}</InfoData>
              </InfoBlock>
              {index < userData.courses.length - 1 && <Divider />}{" "}
              {/* Add divider except for the last course */}
            </courseDetails>
          ))}
        </CourseContainer>

        {/* Fees */}
        <CourseContainer>
          <CourseTitle>Fees</CourseTitle>
          {userData?.fees?.map((fee, index) => (
            <courseDetails key={index}>
              <InfoBlock>
                <InfoLabel>Amount</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{fee.amount}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Date</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{fee.date}</InfoData>
              </InfoBlock>
              <InfoBlock>
                <InfoLabel>Description</InfoLabel>
                <InfoSeparator>-</InfoSeparator>
                <InfoData>{fee.description}</InfoData>
              </InfoBlock>
              {index < userData.fees.length - 1 && <Divider />}{" "}
              {/* Add divider except for the last fee */}
            </courseDetails>
          ))}
        </CourseContainer>
      </ProfileCard>
    </PageContainer>
  );
};

export default FeesPage;
