import React from "react";
import Slider from "./Slider";
import LeadersPage from "./Leader";
import OfferPopup from "./OfferPopup";
// import SocialMediaDashboard from "./SocialMedia";
import TestimonialsPage from "./Testimonials";
import CertificatesPage from "./Certificates";

const HomePage = () => {
  return (
    <div>
      {/* Other content on your homepage */}
      <Slider />
      <CertificatesPage/>
      <LeadersPage/>
      <OfferPopup />
      {/* <SocialMediaDashboard/> */}
      <TestimonialsPage/>
      {/* <SreefCarousel/> */}

    </div>
  );
};

export default HomePage;
