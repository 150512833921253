import React, { useState, useEffect } from 'react';

const ConnectionChecker = ({ onRetryComplete }) => {
  const [, setOnlineStatus] = useState(navigator.onLine);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const maxRetries = 10;
  const retryInterval = 5000; // Retry interval in milliseconds
  const retryCheckDuration = 500; // Duration for each retry check in milliseconds

  useEffect(() => {
    const handleOnline = () => {
      setOnlineStatus(true);
      setModalMessage('We are back now! Enjoy.');
      setTimeout(() => {
        setShowModal(false);
        if (typeof onRetryComplete === 'function') {
          onRetryComplete(true);
        } else {
          console.error('onRetryComplete is not a function');
        }
      }, 2000);
    };

    const handleOffline = () => {
      setOnlineStatus(false);
      setShowModal(true);
      setModalMessage('Connection lost. Checking connection...');
      setRetryCount(0);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [onRetryComplete]);

  useEffect(() => {
    let progressInterval;
    let retryTimer;

    if (showModal) {
      setModalMessage('Checking connection...');
      setProgress(0);

      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + (100 / (retryInterval / retryCheckDuration));
          if (newProgress >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return newProgress;
        });
      }, retryCheckDuration);

      retryTimer = setInterval(() => {
        if (navigator.onLine) {
          setModalMessage('We are back now! Enjoy.');
          clearInterval(retryTimer);
          setTimeout(() => {
            setShowModal(false);
            if (typeof onRetryComplete === 'function') {
              onRetryComplete(true);
            } else {
              console.error('onRetryComplete is not a function');
            }
          }, 2000);
        } else if (retryCount < maxRetries) {
          setRetryCount((prevCount) => prevCount + 1);
          setProgress(0);
        } else {
          setModalMessage('Unable to reconnect. Please check your connection manually.');
          clearInterval(progressInterval);
          clearInterval(retryTimer);
        }
      }, retryInterval);

      return () => {
        clearInterval(progressInterval);
        clearInterval(retryTimer);
      };
    }
  }, [showModal, retryCount, onRetryComplete]);

  const handleRetry = () => {
    setRetryCount(0);
    setShowModal(true);
  };

  return (
    <div>
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <p>{modalMessage}</p>
            <div style={styles.progressBar}>
              <div
                style={{
                  ...styles.progress,
                  width: `${progress}%`,
                }}
              />
            </div>
            {modalMessage === 'Unable to reconnect. Please check your connection manually.' && (
              <button onClick={handleRetry} style={styles.retryButton}>
                Retry
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    maxWidth: '400px',
    width: '80%',
  },
  progressBar: {
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    overflow: 'hidden',
    marginTop: '20px',
  },
  progress: {
    height: '10px',
    backgroundColor: '#007bff',
    transition: 'width 0.5s ease',
  },
  retryButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default ConnectionChecker;
