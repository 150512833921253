import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaHome, FaInfo, FaPhone, FaBars, FaBook, FaImages, FaSignInAlt } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import { getDocs, query, where, collection } from "firebase/firestore";
import { db } from "./firebase";

const HeaderContainer = styled.header`
  background-color: teal;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100;
  margin: 0;
  align-items: center;
  color: white;
  z-index: 1000;
`;

const Title = styled.h1`
  margin: 0;
`;

const Nav = styled.nav`
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }

  a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;

    &.active {
      color: #ffeb3b;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

const MenuIcon = styled.div`
  font-size: 1.5em;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  width: 250px;
  height: 100%;
  background-color: teal;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: left 0.3s ease;
  z-index: 999;
`;

const SidebarHeader = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SidebarLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;

  &.active {
    color: #ffeb3b;
  }

  &:hover {
    color: #007bff;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userFullName, setUserFullName] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user.uid); // Fetch user data when user is logged in
      } else {
        setUserFullName(null);
        setUserImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const q = query(collection(db, 'students'), where('uid', '==', uid));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          setUserFullName(userData.fullName);
          if (userData.imageUrl) {
            setUserImage(userData.imageUrl);
          }
        });
      } else {
        console.log("No matching documents.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const closeSidebar = () => setSidebarOpen(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserFullName(null);
      setUserImage(null);
      console.log("Logged out successfully");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const links = [
    { to: "/", icon: <FaHome />, label: "Home" },
    { to: "/about", icon: <FaInfo />, label: "About" },
    { to: "/contact", icon: <FaPhone />, label: "Contact" },
    { to: "/courses", icon: <FaBook />, label: "Courses" },
    { to: "/gallery", icon: <FaImages />, label: "Gallery" },
    { to: "/career", icon: <FaImages />, label: "Career" },
    { to: "/login", label: userFullName ? <ProfileIcon><ProfileImage src={userImage} alt="Profile" /></ProfileIcon> : "Login" },
  ];

  return (
    <>
      <HeaderContainer>
        <Title>Sreef</Title>
        <Nav>
          <ul>
            {links.map(({ to, icon, label }) => (
              <li key={to}>
                <Link to={to} onClick={closeSidebar} className={location.pathname === to ? "active" : ""}>
                  {icon} {label}
                </Link>
              </li>
            ))}
          </ul>
        </Nav>
        <MenuIcon onClick={toggleSidebar}>
          <FaBars />
        </MenuIcon>
      </HeaderContainer>
      <SidebarContainer isOpen={sidebarOpen}>
        <SidebarHeader>Sreef</SidebarHeader>
        {links.map(({ to, icon, label }) => (
          <SidebarLink key={to} to={to} onClick={closeSidebar} className={location.pathname === to ? "active" : ""}>
            {icon} {label}
          </SidebarLink>
        ))}
        {userFullName && (
          <SidebarLink to="#" onClick={handleLogout}>
            <FaSignInAlt /> Logout
          </SidebarLink>
        )}
      </SidebarContainer>
      <Overlay isOpen={sidebarOpen} onClick={toggleSidebar} />
    </>
  );
};

export default Header;
