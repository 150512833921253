import React from "react";
import styled from "styled-components";

const NotFound = () => {
  return (
    <Container>
      <Message>404 - Page Not Found</Message>
      <Description>The page you are looking for does not exist.</Description>
      <HomeLink href="/">Go to Homepage</HomeLink>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  color: #333;
`;

const Message = styled.h1`
  font-size: 3rem;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const HomeLink = styled.a`
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default NotFound;
