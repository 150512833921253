import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Preloader from './Preloader'; // Adjust the path as per your project structure

const Career = () => {
  const [careerItems, setCareerItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const fetchCareerItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'careerItems'));
        const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCareerItems(itemsList);
      } catch (error) {
        console.error('Error fetching career items:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCareerItems();
  }, [db]);

  return (
    <CareerContainer>
      {loading && <Preloader />}
      <WelcomeMessage>
        <h2>Welcome to the Career Page</h2>
        <p>
          Welcome to the career page of Sita Ram Elegant Education Foundation! We are thrilled that you are considering joining our team.
        </p>
        <p>
          Sita Ram Elegant Education Foundation is a non-profit organization that is dedicated to providing quality education and opportunities for the underprivileged children in India. We believe that education is the key to unlocking a better future for our children and our society as a whole. Our mission is to create a world where every child has access to quality education regardless of their background.
        </p>
        <p>
          At Sita Ram Elegant Education Foundation, we have a team of passionate individuals who are committed to making a positive impact on the lives of children. We believe that our employees are our greatest asset, and we strive to create a supportive and inclusive work environment where everyone can thrive.
        </p>
      </WelcomeMessage>
      <h2>Available Career Opportunities</h2>
      <CareerItems>
        {careerItems.length > 0 ? (
          careerItems.map(item => (
            <CareerCard key={item.id}>
              <CareerInfo>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                {item.link && (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">More Info And Apply Link</a>
                )}
              </CareerInfo>
            </CareerCard>
          ))
        ) : (
          <p>No career opportunities available at the moment.</p>
        )}
      </CareerItems>
      <WarningNote>
        <p>
          <strong>Note:</strong> Sita Ram Elegant Education Foundation is the sole provider of these career opportunities. We do not provide the job opportunities, and we do not endorse any third-party job providers. Please be cautious and ensure you are applying through our official channels only.
        </p>
      </WarningNote>
    </CareerContainer>
  );
};

const CareerContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;

  h2 {
    margin-bottom: 20px;
    color: teal;
    text-align: left;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const WelcomeMessage = styled.div`
  margin-bottom: 30px;
  text-align: left;
  padding: 20;
  
  p {
    margin-bottom: 15px;
    color: #333;
    line-height: 1.6;
  }
`;

const CareerItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
`;

const CareerCard = styled.div`
  padding: 20px;
  border: 1px solid teal;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CareerInfo = styled.div`
  text-align: left;

  h3 {
    margin: 0 0 10px;
    font-size: 1.5rem;
    color: teal;
  }

  p {
    margin: 0 0 15px;
    color: #555;
  }

  a {
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const WarningNote = styled.div`
  margin-top: 30px;
  padding: 15px;
  background-color: #ffe9e9;
  border: 1px solid #ffcccc;
  border-radius: 8px;
  color: #d9534f;

  p {
    margin: 0;
    text-align: left;
  }
`;

export default Career;
