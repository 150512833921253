import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase'; // Adjust the path as per your Firebase setup
import styled from 'styled-components';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineArrowRight } from 'react-icons/ai';

// Styled components
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #56ccf2, #2f80ed);
  padding: 20px;
  min-height: 100vh;
  color: #333;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 10px 0;
  width: 250px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background: #f0f0f0;
  }
`;

const CardText = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

const ArrowIcon = styled(AiOutlineArrowRight)`
  font-size: 1.2rem;
  color: #333;
`;

const LogoutIcon = styled(FiLogOut)`
  font-size: 1.2rem;
  color: #333;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
`;

const ModalText = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

const ModalButton = styled.button`
  margin: 20px 10px 0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: #4caf50;
  color: white;
  cursor: pointer;
  &:hover {
    background: #45a049;
  }
`;

const ModalCancelButton = styled(ModalButton)`
  background: #f44336;
  &:hover {
    background: #e53935;
  }
`;

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Firebase listener to check authentication state
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null); // No user signed in
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Firebase sign out method
      // Redirect to login page or home page after logout
      window.location.href = '/'; // Redirect using window.location
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <DashboardContainer>
      <Title>Welcome to Dashboard</Title>
      {currentUser && (
        <>
          <Card onClick={() => navigate('/studentprofile')}>
            <CardText>View Profile</CardText>
            <ArrowIcon />
          </Card>
          <Card onClick={() => navigate('/studentcourse')}>
            <CardText>View Course</CardText>
            <ArrowIcon />
          </Card>
          <Card onClick={() => navigate('/studentfees')}>
            <CardText>View Fees</CardText>
            <ArrowIcon />
          </Card>
          <Card onClick={() => navigate('/studentcertificates')}>
            <CardText>View Certificates</CardText>
            <ArrowIcon />
          </Card>
          <Card onClick={openModal}>
            <CardText>Logout</CardText>
            <LogoutIcon />
          </Card>
        </>
      )}

      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalText>Are you sure you want to logout?</ModalText>
            <ModalButton onClick={handleLogout}>Yes, Logout</ModalButton>
            <ModalCancelButton onClick={closeModal}>Cancel</ModalCancelButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </DashboardContainer>
  );
};

export default Dashboard;
