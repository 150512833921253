import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Adjust the import path if necessary
import styled, { keyframes } from "styled-components";

const LeadersPage = () => {
  const [leaders, setLeaders] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState(null);

  useEffect(() => {
    const fetchLeaders = async () => {
      const leadersCollection = collection(db, "leaders"); // Adjust collection name as necessary
      const leadersSnapshot = await getDocs(leadersCollection);
      const leadersList = leadersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaders(leadersList);
    };

    fetchLeaders();
  }, []);

  const handleCardClick = (leader) => {
    setSelectedLeader(selectedLeader?.id === leader.id ? null : leader);
  };

  useEffect(() => {
    if (selectedLeader) {
      const timer = setTimeout(() => {
        setSelectedLeader(null);
      }, 5000); // Hide information after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [selectedLeader]);

  return (
    <Container>
      <Header>
        <Title>Leaders of India</Title>
        <Subtitle>
          Discover the visionary leaders who have shaped the course of India's
          history. These individuals have made significant contributions to the
          country's growth and development, inspiring future generations with
          their leadership and dedication.
        </Subtitle>
      </Header>
      <CardsContainer>
        {leaders.map((leader) => (
          <LeaderCard
            key={leader.id}
            onClick={() => handleCardClick(leader)}
            isSelected={selectedLeader?.id === leader.id}
          >
            <LeaderImage src={leader.imageUrl || "https://via.placeholder.com/400x400"} alt={leader.name} />
            <LeaderContent isVisible={selectedLeader?.id === leader.id}>
              <LeaderName>{leader.name}</LeaderName>
              <LeaderDescription>{leader.description}</LeaderDescription>
            </LeaderContent>
            <LeaderOverlay isVisible={selectedLeader?.id === leader.id} />
            {selectedLeader?.id === leader.id && <ProgressBar />}
          </LeaderCard>
        ))}
      
      </CardsContainer>
    </Container>
  );
};

const progressBarAnimation = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  color: white;
  background-color: #001a33;
  padding: 10px;
  position: relative;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  overflow-x: auto;
  max-height: 70vh;
  padding: 5px;
  position: relative;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const LeaderCard = styled.div`
  flex: 0 0 auto;
  width: 185px;
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  padding: 3px;
  position: relative;
  border: 2px solid transparent;
  transition: transform 0.3s ease-in-out;
`;

const LeaderImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: fill;
  border: 1px solid teal;
`;

const LeaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const LeaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background: teal;
  animation: ${progressBarAnimation} 5s linear forwards;
`;

const LeaderName = styled.h2`
  font-size: 1.5rem;
  margin: 10px 0;
`;

const LeaderDescription = styled.p`
  font-size: 1rem;
  margin: 5px 0;
`;

export default LeadersPage;
