import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import styled from 'styled-components';
import Preloader from './Preloader'; // Assuming a Preloader component exists
import { FaFacebook, FaYoutube, FaWhatsapp, FaInstagram, FaTwitter } from 'react-icons/fa'; // Importing Font Awesome icons for social media

const TestimonialsPage = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      setLoading(true);
      const db = getFirestore();
      try {
        const querySnapshot = await getDocs(collection(db, 'testimonials'));
        const testimonialsList = [];
        querySnapshot.forEach((doc) => {
          testimonialsList.push({ id: doc.id, ...doc.data() });
        });
        setTestimonials(testimonialsList);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <TestimonialsContainer>
      <PreloaderComponent loading={loading} />
      <Header>
        <h1>Testimonials</h1>
        <Subtitle>{testimonials.length} {testimonials.length === 1 ? 'Testimonial' : 'Testimonials'}</Subtitle>
        <Subtitle>Discover what our community is saying about us.</Subtitle> {/* Example subtitle */}
      </Header>
      <TestimonialsList>
        {testimonials.length > 0 ? (
          testimonials.map((testimonial, index) => (
            <TestimonialCard key={testimonial.id}>
              <IndexDisplay>{index + 1}/{testimonials.length}</IndexDisplay>
              <TestimonialImage src={testimonial.imageUrl} alt={testimonial.name} />
              <TestimonialInfo>
                <h3>{testimonial.name}</h3>
                <SocialLinks>
                  {testimonial.socialLinks.facebook && (
                    <a href={testimonial.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                      <FaFacebook />
                    </a>
                  )}
                  {testimonial.socialLinks.youtube && (
                    <a href={testimonial.socialLinks.youtube} target="_blank" rel="noopener noreferrer">
                      <FaYoutube />
                    </a>
                  )}
                  {testimonial.socialLinks.whatsapp && (
                    <a href={testimonial.socialLinks.whatsapp} target="_blank" rel="noopener noreferrer">
                      <FaWhatsapp />
                    </a>
                  )}
                  {testimonial.socialLinks.instagram && (
                    <a href={testimonial.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  )}
                  {testimonial.socialLinks.twitter && (
                    <a href={testimonial.socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                      <FaTwitter />
                    </a>
                  )}
                </SocialLinks>
                <Description>{testimonial.description}</Description>
              </TestimonialInfo>
            </TestimonialCard>
          ))
        ) : (
          <p>No testimonials available.</p>
        )}
      </TestimonialsList>
    </TestimonialsContainer>
  );
};

// Styled Components
const TestimonialsContainer = styled.div`
  margin: 0 auto;
  padding: 10px;
  text-align: left;
  overflow-x: hidden; /* Hide the horizontal scroll */
  
  h1 {
    margin-bottom: 20px;
    color: white;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h1 {
    color: black;
  }
`;

const Subtitle = styled.h2`
  color: #cccccc;
  font-size: 1.2rem;
  margin-top: 10px;
`;

const TestimonialsList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 600px) {
    overflow-x: scroll;
  }

  @media (min-width: 1200px) {
    overflow-x: scroll;
  }

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const TestimonialCard = styled.div`
  min-width: 320px;
  border: 2px solid gold;
  background-color: #112244; /* Matches card background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: left;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Ensure positioning for index display */
`;

const IndexDisplay = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: teal;

  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.9rem;
`;

const TestimonialImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
`;

const TestimonialInfo = styled.div`
  flex: 1;
  text-align: center;

  h3 {
    margin-bottom: 10px;
    color: #3498db;
  }
`;

const SocialLinks = styled.div`
  margin: 10px 0;

  a {
    margin-right: 10px;
    color: #3498db;
    font-size: 1.5rem;
    text-decoration: none;

    &:hover {
      color: #2980b9;
    }
  }
`;

const Description = styled.p`
  color: white;
  margin-top: 10px;
  text-align: left;
`;

const PreloaderComponent = ({ loading }) => {
  return loading ? <Preloader /> : null;
};

export default TestimonialsPage;
