import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const Contact = () => {
  const [sliderItems, setSliderItems] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchSliderItems = async () => {
      const db = getFirestore();
      try {
        const querySnapshot = await getDocs(collection(db, "sliderItems"));
        const itemsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSliderItems(itemsList);
      } catch (error) {
        console.error("Error fetching slider items:", error);
      }
    };

    fetchSliderItems();
  }, []);

  useEffect(() => {
    const interval = sliderItems.length > 1 
      ? setInterval(() => {
          setCurrentImageIndex(prevIndex =>
            prevIndex === sliderItems.length - 1 ? 0 : prevIndex + 1
          );
        }, 5000)
      : null;

    return () => interval && clearInterval(interval);
  }, [sliderItems]);

  const changeSlide = (index) => {
    setCurrentImageIndex(index);
  };

  const handleImageClick = () => {
    const currentItem = sliderItems[currentImageIndex];
    if (currentItem?.link) {
      window.open(currentItem.link, "_blank");
    }
  };

  const renderBullets = () =>
    sliderItems.map((_, index) => (
      <button
        key={index}
        style={{
          ...styles.bullet,
          backgroundColor: index === currentImageIndex ? "teal" : "gray",
        }}
        onClick={() => changeSlide(index)}
      />
    ));

  return (
    <div style={styles.container}>
      <div style={styles.sliderContainer}>
        <img
          src={sliderItems[currentImageIndex]?.imageUrl}
          alt={sliderItems[currentImageIndex]?.title}
          style={styles.image}
          onClick={handleImageClick}
        />
        <div style={styles.overlay}>
          <h2 style={styles.title}>{sliderItems[currentImageIndex]?.title}</h2>
          <button
            style={styles.prevButton}
            onClick={(e) => {
              e.stopPropagation();
              changeSlide(
                currentImageIndex === 0
                  ? sliderItems.length - 1
                  : currentImageIndex - 1
              );
            }}
          >
            &#10094;
          </button>
          <button
            style={styles.nextButton}
            onClick={(e) => {
              e.stopPropagation();
              changeSlide(
                currentImageIndex === sliderItems.length - 1
                  ? 0
                  : currentImageIndex + 1
              );
            }}
          >
            &#10095;
          </button>
        </div>
      </div>
      <div style={styles.bulletContainer}>{renderBullets()}</div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    lineHeight: "1.6",
    margin: 8,
    border: "2px solid teal",
    overflow: "hidden",
  },
  sliderContainer: {
    position: "relative",
    width: "100%",
    height: "300px",
    overflow: "hidden",
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
    transition: "opacity 0.5s ease-in-out",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    boxSizing: "border-box",
    top: 0,
    left: 0,
  },
  title: {
    fontSize: "1.5rem",
    textAlign: "left",
    color: "#fff",
    position: "absolute",
    top: "10px",
    left: "10px",
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px",
    borderRadius: "5px",
  },
  bulletContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  bullet: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    border: "none",
    margin: "0 5px",
    cursor: "pointer",
  },
  prevButton: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    padding: "10px",
    cursor: "pointer",
    zIndex: 2,
  },
  nextButton: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    padding: "10px",
    cursor: "pointer",
    zIndex: 2,
  },
};

export default Contact;
