

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { auth, db } from './firebase'; // Adjust the path as per your Firebase setup
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

// Styled components
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  color: #333;
  padding: 20px;
`;

const ProfileCard = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 100%;
  margin: 20px 0;
  animation: fadeIn 0.6s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const UserImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
  display: block;
  border: 3px solid #4a90e2;
`;

const Title = styled.h2`
  text-align: center;
  color: #4a90e2;
  margin-bottom: 10px;
  font-size: 1.8rem;
  font-weight: bold;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  font-size: 1.1rem;
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: white;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  background: teal;
  color: white;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2rem;
`;

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userUID, setUserUID] = useState(null); // State to store the user's UID
  const [feesSummary, setFeesSummary] = useState({}); // State to store the fees summary

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const docRef = doc(db, 'students', uid); // Correct the collection name to 'teststudents'
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);
          
          // Calculate total fees received
          const totalFeesReceived = data.fees?.reduce((total, fee) => total + fee.amount, 0) || 0;

          // Calculate total course fees
          const totalCourseFees = data.courses?.reduce((total, course) => total + course.courseFees, 0) || 0;

          // Calculate remaining fees
          const remainingFees = totalCourseFees - totalFeesReceived;

          setFeesSummary({
            totalFeesReceived,
            totalCourseFees,
            remainingFees
          });
        } else {
          console.log('No matching user found!');
          setError('No user found');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUID(user.uid); // Set the user's UID
        fetchUserData(user.uid);
      } else {
        setLoading(false);
        setError('No user is logged in');
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <Error>{error}</Error>;
  }

  return (
    <PageContainer>
      <ProfileCard>
        {userData ? (
          <>
            <UserImage src={userData.imageUrl || 'path-to-default-user-image.jpg'} alt="User Profile" />
            <Title>{userData.fullName}</Title>
            <Subtitle>{userData.email}</Subtitle>
            <Subtitle>User UID: {userUID}</Subtitle> {/* Display the user's UID */}
            <TableContainer>
              <Table>
                <tbody>
                  <TableRow>
                    <TableHeader>Username</TableHeader>
                    <TableCell>{userData.username}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Full Name</TableHeader>
                    <TableCell>{userData.fullName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Email</TableHeader>
                    <TableCell>{userData.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Mobile Number</TableHeader>
                    <TableCell>{userData.mobileNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Address</TableHeader>
                    <TableCell>{userData.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Father's Name</TableHeader>
                    <TableCell>{userData.fatherName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Mother's Name</TableHeader>
                    <TableCell>{userData.motherName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Date of Birth</TableHeader>
                    <TableCell>{userData.dob}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Gender</TableHeader>
                    <TableCell>{userData.gender}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Category</TableHeader>
                    <TableCell>{userData.category}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Area Pincode</TableHeader>
                    <TableCell>{userData.areaPincode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Aadhar Number</TableHeader>
                    <TableCell>{userData.aadharNumber}</TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
            {userData.courses && userData.courses.length > 0 && (
              <TableContainer>
                <h3>Courses</h3>
                <Table>
                  <thead>
                    <tr>
                      <TableHeader>Center Name</TableHeader>
                      <TableHeader>Course Name</TableHeader>
                      <TableHeader>Course Fees</TableHeader>
                      <TableHeader>University</TableHeader>
                      <TableHeader>Enrollment Session</TableHeader>
                      <TableHeader>Center Head</TableHeader>
                      <TableHeader>Start Date</TableHeader>
                      <TableHeader>End Date</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.courses.map((course, index) => (
                      <TableRow key={index}>
                        <TableCell>{course.centerName}</TableCell>
                        <TableCell>{course.courseName}</TableCell>
                        <TableCell>{course.courseFees}</TableCell>
                        <TableCell>{course.university}</TableCell>
                        <TableCell>{course.enrollmentSession}</TableCell>
                        <TableCell>{course.centerHead}</TableCell>
                        <TableCell>{course.startDate}</TableCell>
                        <TableCell>{course.endDate}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
            {userData.fees && userData.fees.length > 0 && (
              <TableContainer>
                <h3>Fees</h3>
                <Table>
                  <thead>
                    <tr>
                      <TableHeader>Amount</TableHeader>
                      <TableHeader>Date</TableHeader>
                      <TableHeader>Description</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.fees.map((fee, index) => (
                      <TableRow key={index}>
                        <TableCell>{fee.amount}</TableCell>
                        <TableCell>{fee.date}</TableCell>
                        <TableCell>{fee.description}</TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
            <TableContainer>
              <h3>Fees Summary</h3>
              <Table>
                <tbody>
                  <TableRow>
                    <TableHeader>Total Course Fees</TableHeader>
                    <TableCell>{feesSummary.totalCourseFees}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Total Fees Received</TableHeader>
                    <TableCell>{feesSummary.totalFeesReceived}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Remaining Fees</TableHeader>
                    <TableCell>{feesSummary.remainingFees}</TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Error>No user data to display.</Error>
        )}
      </ProfileCard>
    </PageContainer>
  );
};

export default ProfilePage;
